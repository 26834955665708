<template>
	<edit-template class="role-edit" ref="edit" @confirm="save('form')" @cancel="open" :cancelRemind="false" confirmText="保存" cancelText="取消">

		<!-- 大表单 -->
		<div class="form-body">
			<el-form ref="form" :model="form" :rules="rules" label-width="150rem">
				<el-form-item label="一级科目大类" prop="pid">
					<el-select :popper-append-to-body="false" v-model="form.pid" placeholder="请选择对应科目类">
						<el-option v-for="item in fistSubjectArr" :key="item.id" :label="item.subject_name" :value="item.id"> </el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="二级科目小类" prop="subject_name">
					<el-input v-model="form.subject_name" placeholder="请输入对应科目" style="width: 215rem"></el-input>
				</el-form-item>

				<el-form-item label="适用校区" prop="roles">
					<el-button plain="" @click="choose">点击选择</el-button>
					<br>
					<div v-if="checkList.length > 0">
						<el-tag @close="handleClose(item)" :closable="true" v-for="item in checkList" :key="item.id" style="margin-right: 10rem">{{
								item.school_name
							}}</el-tag>
						<!--						<el-input v-model="form.roles" v-show="false"></el-input>-->
						<el-checkbox-group v-model="form.roles" v-show="false">
							<el-checkbox label="适用校区" name="type"></el-checkbox>
						</el-checkbox-group>
					</div>
				</el-form-item>
<!--        <el-form-item label="适用学段" prop="grade">-->
<!--          <el-radio-group v-model="form.grade">-->
<!--            <el-radio label="高中"></el-radio>-->
<!--            <el-radio label="初中"></el-radio>-->
<!--          </el-radio-group>-->
<!--        </el-form-item>-->
			</el-form>
		</div>
		<!-- 底部按钮 -->


		<!-- 点击选择角色弹窗 -->
		<el-dialog title="选择校区" :visible.sync="dialogVisible" width="50%" :before-close="handleCloseTo">
			<el-checkbox v-model="checkAll" @change="handleCheckAllChange" border="" class="checkbox" style="overflow: hidden">全部校区</el-checkbox>
			<el-checkbox-group v-model="checkList" @change="changeList">
				<el-checkbox v-for="item in checkDataList" :label="item" border="" class="checkbox">{{ item.school_name }}</el-checkbox>
			</el-checkbox-group>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisiblefalse">取 消</el-button>
				<el-button type="primary" @click="dialogVisibletrue">确 定</el-button>
			</span>
		</el-dialog>

</edit-template>
</template>

<script>
import { school, subjectAdd, fistSubject } from '@/api/account-management'

export default {
	_config:{"route":{"path":"add","meta":{"title":"新增"}}},
  data() {
		return {
			checkData: [],
			form: {
				subject_name: '', // 科目
				school_ids: '', // 学校
				roles: [],
				pid: ''
			},
			fistSubjectArr: [],
			checkAll: false,
			checkList: [],
			checkDataList: [],
			cheData: [],
			dialogVisible: false,
			schoolList: [],
			rules: {
				subject_name: [
					{ required: true, message: '请输入科目', trigger: 'blur' },
					{ min: 1, max: 10, message: '字数限制在10字以内', trigger: 'blur' }
				],
				roles: [{ required: true, message: '请选择校区', trigger: 'change' }],
				pid: [{ required: true, message: '请选择对应科目类', trigger: 'change' }],
        // grade: [{ required: true, message: '请选择适用学段', trigger: 'change' }],
			}
		}
	},

	methods: {
		save(form) {
			this.$refs[form].validate(valid => {
				if (valid) {
					this.form.school_ids = Array.from(new Set(this.form.roles)).toString()
					subjectAdd(this.form).then(res => {
						if (res.data.error.errorCode !== 0) {
							this.$notify.error({
								title: '新增出错',
								message: res.data.error.errorUserMsg
							})
						} else {
							this.$notify({
								title: '新增成功',
								message: '新增成功',
								type: 'success'
							})
							setTimeout(() => {
								this.$store.commit("setPage",1);
								this.$router.back()
							}, 0)
						}
					})
				} else {
					if (this.form.subject_name.length > 10) {
						this.$message.error('字数限制在10字以内')
					} else this.$message.error('请完善您的信息')
				}
			})
		},
		open() {
			this.$confirm('是否取消新增', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
			.then(() => {
				this.$router.back()
			})
			.catch(() => {})
		},
		handleCloseTo() {
			this.dialogVisiblefalse()
		},
		choose() {
			this.dialogVisible = true
			this.checkData = this.checkList
			this.checkAll = this.checkList.length === this.checkDataList.length;
		},
		changeList() {
			this.checkAll = this.checkList.length === this.checkDataList.length;
		},
		// 取消按钮
		dialogVisiblefalse() {
			this.checkList = this.checkData
			if (this.checkList.length > 0) {
				Array.from(new Set(this.checkList)).forEach(i => {
					this.form.roles.push(i.id)
				})
			}
			this.dialogVisible = false
			Array.from(new Set(this.form.roles))
		},
		// 确定按钮
		dialogVisibletrue() {
			if (this.checkList.length > 0) {
				Array.from(new Set(this.checkList)).forEach(i => {
					this.form.roles.push(i.id)
				})
			} else this.form.roles = []
			this.dialogVisible = false
			Array.from(new Set(this.form.roles))
		},
		// 删除标签
		handleClose(value) {
			this.checkList.splice(this.checkList.indexOf(value), 1)
			this.form.roles.splice(this.form.roles.indexOf(value), 1)
		},
		handleCheckAllChange(val) {
			if (val) {
				this.checkList = []
				this.form.roles = []
				this.checkDataList.forEach(i => {
					this.checkList.push(i)
				})
			} else {
				this.checkList = []
				this.form.roles = []
			}
		}
	},

	created() {
		school().then(res => {
			this.checkDataList = res.data.data
		})
		fistSubject().then(res => {
			this.fistSubjectArr = res.data.data
		})
	}
}
</script>

<style lang="scss" scoped>
.checkbox {
	min-width: 100rem;
	margin: 10rem;
}
</style>
